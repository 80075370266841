@import "global";

.error {
  @include padding(150px null 50px);
  display: table;
  flex-direction: column;
  position: relative;
  @include size(100%);
  justify-content: center;
  text-align: center;
  background-color: $cPrimary;
  color: $cWhite;
  .row {
      display: table-cell;
      vertical-align: middle;
  }
  &__inner {
      z-index: 1;
      position: relative;
  }
}